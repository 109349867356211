@media screen and (max-width: 991px){
  .responsive-login{
    display: flex;
    max-width: 100% !important;
    flex-direction: column;
    background-image: linear-gradient(indigo,indigo,purple);
  }
}

@media screen and (min-width: 992px) and (max-width: 999px){
  .responsive-login{
    display: flex;
    max-width: 500px !important;
    flex-direction: column;
    background-image: linear-gradient(indigo,indigo,purple);
  }
}

@media screen and (min-width: 1000px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 530px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }
}

@media screen and (min-width: 1050px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 570px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }
  }
  
  @media screen and (min-width: 1150px) {
    .responsive-login{
      /* width: 700px !important; */
      max-width: 650px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }
  }
  
  @media screen and (min-width: 1250px) {
    .responsive-login{
      /* width: 850px !important; */
      max-width: 750px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }
  }
  
  @media screen and (min-width: 1350px) {
    .responsive-login{
      /* width: 850px !important; */
      max-width: 900px !important;
      background-image: linear-gradient(indigo,indigo,purple);
    }
  }