// @mixin ScrollBar {
//     scrollbar-width: thick;
//     transition: all 150ms ease-in-out;
   
//     &::-webkit-scrollbar {
//       width: 0.5rem;
    
//       padding-bottom: 0%;
//       height: 0.5rem;              /* height of horizontal scrollbar ← You're missing this */
//     }
//     &::-webkit-scrollbar-track {
//       box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
//     }
//     &::-webkit-scrollbar-thumb {
//       background-color: lightgray;
//       border-radius: 10px;
//     }
//   }

.nav-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .nav-item {
    flex-grow: 1;
    text-align: center;
  }
  
  .nav-link {
    text-align: center;
  }
  
  .detailsCardBh{
    // @include ScrollBar();
    // height: 40vh; 
    // overflow: auto;
  }
  .topbar-hygiene {
    background-color: #530093;
    color: white;
    padding: 1rem;
    margin: 1 rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .fixed-column {
    text-align: center;
    position: sticky;
    top: 0;
    width: 100%;
    max-width: 50%;
    height: 100%;
  }
  