@charset "UTF-8";
.tdCustomContainer table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.tdCustomContainer th {
  font-weight: 550;
  font-size: 12px;
  min-width: 140px;
  border-bottom-width: 1px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* width: 100px; */
  position: sticky;
  background-color: LightGray;
}
.tdCustomContainer td {
  font-weight: 300;
  font-size: 14px;
  /* border: 1px solid #ebedf3; */
  vertical-align: top;
  padding: 0.5rem 0.2rem;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}
.tdCustomContainer .tr {
  width: fit-content;
}
.tdCustomContainer .striped:nth-child(even) {
  background-color: #e9edf0;
}
.tdCustomContainer .hover:hover {
  background-color: #d3d3d3;
}

.tdCustomContainer {
  scrollbar-width: thick;
  transition: all 150ms ease-in-out;
  overflow-y: scroll;
  height: 57vh;
  border-width: 1px;
  border-color: #d3d3d3;
  border-style: solid;
  border-radius: 5px;
  margin: 0.1vh;
}
.tdCustomContainer::-webkit-scrollbar {
  width: 0.5rem;
  padding-bottom: 0%;
  height: 0.5rem; /* height of horizontal scrollbar ← You're missing this */
}
.tdCustomContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}
.tdCustomContainer::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 10px;
}

.TheadFixed {
  position: sticky;
  top: 0;
}

.custom {
  display: flex;
  height: 35px;
  width: 240px;
  margin: 8px;
  margin-left: 4.9vh;
  border: solid 1px #d3d3d3;
  border-radius: 5px;
}

.customButton {
  display: flex;
  height: 35px;
  width: 70px;
  margin: 8px;
  margin-left: 10px;
  justify-content: center;
}